import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["windowResult"];
  }

  connect() {
    this.windowResultTargets.forEach((windowResultTarget) => {
      const currentHtml = $(windowResultTarget).html();
      const newHtml = currentHtml.replace(/{score}/g, windowResultTarget.dataset.result);
      $(windowResultTarget).html(newHtml);
    });

    this.resumeEnded = (_event) => {
      sessionStorage.removeItem("formSubmissionProfile");
    };
  }
}
