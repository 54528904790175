import { Controller } from "stimulus";

import $ from "jquery";
import "slick-carousel/slick/slick.js";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const showDescription = () => {
  $("#moduleDescription .trix-content").css("overflow", "");
  $("#moduleDescription .trix-content").css("display", "");
  $("#moduleDescription .trix-content").css("-webkit-line-clamp", "");
  $("#moduleDescription .trix-content").css("-webkit-box-orient", "");
  $.each(
    $("#moduleDescription .trix-content").children(),
    (index, value) => { $(value).show(); }
  );
};

const hideDescription = () => {
  $("#moduleDescription .trix-content").css("overflow", "hidden");
  $("#moduleDescription .trix-content").css("display", "-webkit-box");
  $("#moduleDescription .trix-content").css("-webkit-line-clamp", "5");
  $("#moduleDescription .trix-content").css("-webkit-box-orient", "vertical");
  $.each(
    $("#moduleDescription .trix-content").children(),
    (index, value) => { if (index > 0) { $(value).hide(); } }
  );
};

export default class extends Controller {
  connect() {
    let showFullDescription = $("#moduleDescription")[0];
    if (showFullDescription !== undefined) {
      showFullDescription = showFullDescription.dataset.show === "false";
      if (showFullDescription && $(window).width() < 768) {
        if ($("#moduleDescription .trix-content").text().length > 85) {
          $("#moduleDescription .readMore").show();
        }
        hideDescription();
      }
    }

    $("#mediaSlickSlider").each((_index, element) => {
      if (element.classList.contains("slick-initialized") === false) {
        $(element).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          prevArrow: $(".next_btn"),
          nextArrow: $(".previous_btn"),
        });
      }
    });

    $("#mediaSlickSlider").on("afterChange", (_event, _slick, _currentSlide, _nextSlide) => {
      $("#mediaSlickSlider .slick-slide").find(".video").each((_index, element) => {
        element.contentWindow.postMessage("{\"event\":\"command\",\"func\":\"pauseVideo\",\"args\":\"\"}", "*");
      });
      $("#mediaSlickSlider .slick-slide").find(".audio").each((_index, element) => {
        element.pause();
      });
    });

    this.addField = (event) => {
      const time = new Date().getTime();
      const regexp = new RegExp($(event.currentTarget).data("id"), "g");
      $(event.currentTarget).before(
        $(event.currentTarget).data("fields").replace(regexp, time)
      );
      event.preventDefault();
    };

    this.removeField = (event) => {
      $(event.currentTarget).prev("input[type=hidden").val("1");
      $(event.currentTarget).closest("li").hide();
      event.preventDefault();
    };

    this.readMore = (_event) => {
      $("#moduleDescription .readMore").hide();
      $("#moduleDescription .readLess").show();
      showDescription();
    };

    this.readLess = (_event) => {
      $("#moduleDescription .readLess").hide();
      $("#moduleDescription .readMore").show();
      hideDescription();
    };
  }

  disconnect() {
    $("#mediaSlickSlider").each((_index, element) => {
      if (element.classList.contains("slick-initialized")) {
        $(element).slick("unslick");
      }
    });
  }
}
