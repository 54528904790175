import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.openHref = (event) => {
      window.location.href = event.currentTarget.dataset.href;
    };

    $("#downloadExcelBtn").on("click", (event) => {
      event.preventDefault();

      $(".download-excel-overlay").show();
      $.ajax({
        url: event.currentTarget.href,
        type: "get",
        success: (response) => {
          const downloadLink = window.document.createElement("a");
          downloadLink.href = response.file_path;
          downloadLink.download = "submissions.xlsx";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
        error: (response) => {
          $(`<div class='container-fluid alert-container pl-0 pr-0'><p id='alert'>${response.responseJSON.error}</p></div>`)
            .insertAfter("#administration-menus");
        },
        complete: () => {
          $(".download-excel-overlay").hide();
        }
      });
    });
  }
}
