import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["buildWrap", "formRender", "submitBtn"];
  }

  connect() {
    const $fbEditor = $(this.buildWrapTarget);
    const $formContainer = $(this.formRenderTarget);

    const fbOptions = {
      container: false,
      formData: this.formRenderTarget.dataset.data,
      dataType: "json",
      label: {
        formRendered: "Form Rendered",
        noFormData: "No form data.",
        other: "Other",
        selectColor: "Select Color"
      },
      render: true,
      notify: {
        error(message) {
          console.log("error");
          return console.log(message);
        },
        success(message) {
          console.log("success");
          return console.log(message);
        },
        warning(message) {
          console.log("warning");
          return console.log(message);
        }
      },
      // onSave: function(evt, _formData) {
      //   evt.preventDefault();
      //   $fbEditor.toggle();
      //   $formContainer.toggle();
      //   $('#formPreview', $formContainer).formRender({ formData: formBuilder.formData });
      // },
      i18n: {
        override: {
          "en-US": {
            addOption: "Nova Opção",
            allFieldsRemoved: "Todos os campos removidos.",
            allowMultipleFiles: "Allow users to upload multiple files",
            autocomplete: "Finish it for me",
            button: "Buttonion",
            cannotBeEmpty: "Este campo não pode estar vazio.",
            checkboxGroup: "Escolha Múltipla",
            className: "Classe",
            clearAllMessage: "Tem a certeza?",
            clear: "Limpar formulário",
            close: "Fechar",
            content: "Conteúdo",
            copy: "Copiar para o Clipboard",
            copyButton: "&#43;",
            copyButtonTooltip: "Copiar",
            dateField: "Data",
            description: "Descrição",
            descriptionField: "Descrição",
            devMode: "Developer Mode",
            editNames: "Editar Nomes",
            editorTitle: "Formulário",
            editXML: "Edit XML",
            enableOther: "Enable &quot;Other&quot;",
            enableOtherMsg: "Let users to enter an unlisted option",
            fieldNonEditable: "Este campo não pode ser editado.",
            fieldRemoveWarning: "Tem a certeza que quer remover este campo?",
            fileUpload: "File Upload",
            formUpdated: "Formulário actualizado",
            getStarted: "Arraste os campos do seu lado direito para esta área",
            header: "Header",
            hide: "Edit",
            hidden: "Hidden Input",
            inline: "Inline",
            inlineDesc: "Display {type} inline",
            label: "Texto",
            labelEmpty: "Este campo não pode estar vazio",
            limitRole: "Limit access to one or more of the following roles:",
            mandatory: "Obrigatório",
            maxlength: "Tamanho máximo",
            minOptionMessage: "Este campo requer no mímino duas opções",
            multipleFiles: "Multiple Files",
            name: "Nome",
            no: "Não",
            noFieldsToClear: "Formulário vazio",
            number: "Número",
            off: "Desligado",
            on: "Ligado",
            option: "Opção",
            options: "Opções",
            optional: "opcional",
            optionLabelPlaceholder: "Label",
            optionValuePlaceholder: "",
            optionEmpty: "Valor para a opção obrigatório",
            other: "Outro",
            paragraph: "Parágrafo",
            placeholder: "Placeholder",
            "placeholder.value": "",
            "placeholder.label": "",
            "placeholder.text": "",
            "placeholder.textarea": "",
            "placeholder.email": "",
            "placeholder.placeholder": "",
            "placeholder.className": "space separated classes",
            "placeholder.password": "Enter your password",
            preview: "Preview",
            radioGroup: "Checkbox",
            radio: "Checkbox",
            removeMessage: "Remover Elemento",
            removeOption: "Remover Opção",
            remove: "&#215;",
            required: "Obrigatório",
            richText: "Rich Text Editor",
            roles: "Access",
            rows: "Linhas",
            save: "Preview",
            selectOptions: "Opções",
            select: "Dropdown",
            selectColor: "Selecionar côr",
            selectionsMessage: "Permitir Selecção Multipla",
            size: "Size",
            "size.xs": "Muito pequeno",
            "size.sm": "Pequeno",
            "size.m": "Default",
            "size.lg": "Grande",
            style: "Style",
            styles: {
              btn: {
                default: "Default",
                danger: "Danger",
                info: "Info",
                primary: "Primary",
                success: "Success",
                warning: "Warning"
              }
            },
            subtype: "Type",
            text: "Texto",
            textArea: "Área de texto",
            toggle: "Toggle",
            warning: "Warning!",
            value: "Valor",
            viewJSON: "{  }",
            viewXML: "&lt;/&gt;",
            yes: "Sim"
          }
        }
      },
      disabledActionButtons: ["data", "save"],
      disableFields: [
        "autocomplete",
        "button",
        // "checkbox-group",
        "date",
        "file",
        // 'header',
        "hidden",
        // 'number',
        // 'paragraph',
        // 'radio-group',
        // 'select',
        "starRating",
        // 'text',
        "textarea"
      ],
      controlOrder: [
        "header",
        "paragraph",
        "number",
        "text",
        "checkbox-group",
        "select"
      ],
      disabledAttrs: [
        "access",
        "className",
        "description",
        "inline",
        // 'label',
        // 'max',
        "maxlength",
        // 'min',
        "multiple",
        "name",
        // 'options',
        "other",
        "placeholder",
        // 'required',
        // 'rows',
        "step",
        "style",
        "subtype",
        "toggle",
        // 'value'
      ]
    };
    const formBuilder = $fbEditor.formBuilder(fbOptions);

    $(this.submitBtnTarget).on("click", () => {
      var temp_form_data = formBuilder.formData.replace(/style=\\\"[^\\]*\\"/g,"");
      $formContainer.append(
        `<input type='hidden' value='${temp_form_data}' name='dynamic_form[dynamic_form_json]' id='dynamic_form_dynamic_form_json'>`
      );
    });

    $(".edit-form", $formContainer).on("click", (event) => {
      event.preventDefault();
      $fbEditor.toggle();
      $formContainer.toggle();
      $("#formPreview", $formContainer).formRender({ formData: formBuilder.formData });
    });
  }
}
