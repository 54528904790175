import { Controller } from "stimulus";

const showDescription = () => {
  $("#aboutUsDescription .content").css("overflow", "");
  $("#aboutUsDescription .content").css("display", "");
  $("#aboutUsDescription .content").css("-webkit-line-clamp", "");
  $("#aboutUsDescription .content").css("-webkit-box-orient", "");
  $.each(
    $("#aboutUsDescription .content").children(),
    (_index, value) => { $(value).show(); }
  );
};

const hideDescription = () => {
  $("#aboutUsDescription .content").css("overflow", "hidden");
  $("#aboutUsDescription .content").css("display", "-webkit-box");
  $("#aboutUsDescription .content").css("-webkit-line-clamp", "5");
  $("#aboutUsDescription .content").css("-webkit-box-orient", "vertical");
  $.each(
    $("#aboutUsDescription .content").children(),
    (index, value) => { if (index > 0) { $(value).hide(); } }
  );
};

export default class extends Controller {
  connect() {
    if ($(window).width() < 768) {
      if ($("#aboutUsDescription .content").text().length > 85) {
        $("#aboutUsDescription .readMore").show();
      }
      hideDescription();
    }

    this.readMore = (_event) => {
      $("#aboutUsDescription .readMore").hide();
      $("#aboutUsDescription .readLess").show();
      showDescription();
    };

    this.readLess = (_event) => {
      $("#aboutUsDescription .readLess").hide();
      $("#aboutUsDescription .readMore").show();
      hideDescription();
    };
  }
}
