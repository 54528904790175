import { Controller } from "stimulus";

import {
  clearValidationsFieldsWithErrors,
  addValidationsFieldsWithErrors,
  scrollToElement
} from "./concerns/inputErrorMessagesConcern.js";

export default class extends Controller {
  static get targets() {
    return ["formRender", "formSubmit"];
  }

  connect() {
    const $formContainer = $(this.formRenderTarget);
    const fbOptions = {
      formData: this.formRenderTarget.dataset.formData,
      notify: {
        error(message) {
          // console.log("error");
          return console.error(message);
        },
        success(message) {
          // console.log("success");
          return console.log(message);
        },
        warning(message) {
          // console.log("warning");
          return console.warn(message);
        }
      }
    };
    $formContainer.formRender(fbOptions);

    $(this.formSubmitTarget).on("click", (event) => {
      event.preventDefault();
      clearValidationsFieldsWithErrors(event);

      if (event.currentTarget.closest("form").checkValidity() === true) {
        $(event.currentTarget.closest("form")).submit();
      } else {
        addValidationsFieldsWithErrors(event);

        const errorsMessages = $(".field_with_errors-inline");
        if (errorsMessages.length > 0) { scrollToElement(errorsMessages[0]); }
      }
    });
  }
}
