import { Controller } from "stimulus";
import Swal from "sweetalert2";

const setNewStyle = (target, show) => {
  if (show) {
    $(target).css({ display: "block" });
  } else {
    $(target).css({ display: "none" });
  }
};

// Display the confirmation dialog
const showConfirmationDialog = (element, callback) => {
  Swal.fire({
    title: "Tem a certeza?",
    text: "Se tiver Scores ou Textos para Resultados e guardar o Módulo irá perde-los.",
    icon: "error",
    showCancelButton: true,
    confirmButtonText: "Sim",
    cancelButtonText: "Não",
  }).then((result) => callback(element, result));
};

const triggerEvent = (element, eventName) => {
  const event = document.createEvent("HTMLEvents");
  event.initEvent(eventName, false, true);
  element.dispatchEvent(event);
};

export default class extends Controller {
  static get targets() {
    return ["doTheMath", "formEditBtn", "doTheMathStuff"];
  }

  connect() {
    const doTheMath = this.doTheMathTarget.dataset.value;
    setNewStyle(this.doTheMathStuffTarget, doTheMath === "true");

    this.addField = (event) => {
      const time = new Date().getTime();
      const regexp = new RegExp($(event.currentTarget).data("id"), "g");
      $(event.currentTarget).before(
        $(event.currentTarget).data("fields").replace(regexp, time)
      );
      event.preventDefault();
    };

    this.removeField = (event) => {
      $(event.currentTarget).prev("input[type=hidden]").val("1");
      $(event.currentTarget).closest("li").hide();
      event.preventDefault();
    };

    this.do_the_math_changed = (event) => {
      setNewStyle(this.doTheMathStuffTarget, event.currentTarget.checked);

      if (event.currentTarget.checked === false) {
        showConfirmationDialog(event.currentTarget, (element, result) => {
          if (result.value !== true) {
            element.checked = !element.checked;
            triggerEvent(element, "change");
          }
        });
      }
    };

    this.dynamic_form_id_changed = (event) => {
      const oldValue = event.currentTarget.dataset.value;
      const currentValue = event.currentTarget.value;
      if (oldValue !== undefined && currentValue !== oldValue && doTheMath) {
        showConfirmationDialog(event.currentTarget, (element, result) => {
          if (result.value !== true) {
            element.value = oldValue;
          } else if (this.doTheMathTarget.checked) {
            this.doTheMathTarget.checked = false;
            setNewStyle(this.doTheMathStuffTarget, this.doTheMathTarget.checked);
          }
        });
      }
    };
  }
}
