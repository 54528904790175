import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  connect() {
    this.homePageClick = (event) => {
      const nextUrl = event.currentTarget.href;
      if (sessionStorage.getItem("formSubmissionProfile") === "true") {
        event.preventDefault();
        Swal.fire({
          title: "Quer mesmo sair?",
          text: "Irá perder todas as suas respostas.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((result) => {
          if (result.value === true) {
            sessionStorage.removeItem("formSubmissionProfile");
            window.location.href = nextUrl;
          }
        });
      }
    };
  }
}
