const inputErrorMessages = (errorKey) => {
  const messages = {
    badInput: "Formato inválido",
    customError: "",
    patternMismatch: "Formato inválido",
    rangeOverflow: "Valor acima do permitido",
    rangeUnderflow: "Valor abaixo do permitido",
    stepMismatch: "",
    tooLong: "Texto muito grande",
    tooShort: "Texto muito pequeno",
    typeMismatch: "Formato inválido",
    valid: "",
    valueMissing: "Campo obrigatório"
  };
  return messages[errorKey];
};

const inputCheckValidityErrorMsgs = (inputValidity, input) => {
  let errorsMessage = "";
  $.each(inputValidity, (key, value) => {
    if (value) {
      if (key === "rangeOverflow") {
        errorsMessage = `${errorsMessage} ${inputErrorMessages(key)} (${input.max})`;
      } else if (key === "rangeUnderflow") {
        errorsMessage = `${errorsMessage} ${inputErrorMessages(key)} (${input.min})`;
      } else {
        errorsMessage = `${errorsMessage} ${inputErrorMessages(key)}`;
      }
    }
  });
  return errorsMessage;
};

const addValidationsErrorsForInputsAndSelects = (event) => {
  $.each($(event.currentTarget.closest("form")).find("input[type=text], input[type=number], select"), (_index, input) => {
    if (input.checkValidity() === false) {
      $(`<span class='field_with_errors-inline'>&nbsp;${inputCheckValidityErrorMsgs(input.validity, input)}</span>`).insertAfter(input);
    }
  });
};

const addValidationsErrorsForRadios = (event) => {
  const radioGroupValidities = {};
  $.each($(event.currentTarget.closest("form")).find("input[type=radio]"), (_index, input) => {
    const radioGroupParent = $(input).closest(".formbuilder-radio-group");
    const radioGroupParentClasses = radioGroupParent.attr("class").split(/\s+/);
    const radioGroupParentClassID = radioGroupParentClasses[radioGroupParentClasses.length - 1];
    if (radioGroupValidities[radioGroupParentClassID] === undefined) {
      radioGroupValidities[radioGroupParentClassID] = true;
    }
    if (input.checkValidity() === false) {
      radioGroupValidities[radioGroupParentClassID] = input.validity;
    }
  });
  $.each(radioGroupValidities, (key, value) => {
    if (value !== true
        && $(`.${key}`).find(".field_with_errors-inline").length === 0) {
      $(event.currentTarget.closest("form")).find(`.${key}`)
        .append(`<span class='field_with_errors-inline'>&nbsp;${inputCheckValidityErrorMsgs(value, null)}</span>`);
    }
  });
};

const addValidationsErrorsForCheckboxes = (event) => {
  const checkboxGroupValidities = {};
  $.each($(event.currentTarget.closest("form")).find("input[type=checkbox]"), (_index, input) => {
    const checkboxGroupParent = $(input).closest(".formbuilder-checkbox-group");
    if (checkboxGroupParent.length > 0) {
      const checkboxGroupParentClasses = checkboxGroupParent.attr("class").split(/\s+/);
      const classesLength = checkboxGroupParentClasses.length - 1;
      const checkboxGroupParentClassID = checkboxGroupParentClasses[classesLength];
      if (checkboxGroupValidities[checkboxGroupParentClassID] === undefined) {
        checkboxGroupValidities[checkboxGroupParentClassID] = true;
      }
      if (input.checkValidity() === false) {
        checkboxGroupValidities[checkboxGroupParentClassID] = input.validity;
      }
    }
  });
  $.each(checkboxGroupValidities, (key, value) => {
    if (value !== true
        && $(`.${key}`).find(".field_with_errors-inline").length === 0) {
      $(event.currentTarget.closest("form")).find(`.${key}`)
        .append(`<span class='field_with_errors-inline'>&nbsp;${inputCheckValidityErrorMsgs(value, null)}</span>`);
    }
  });
};

export const clearValidationsFieldsWithErrors = (event) => {
  $.each($(event.currentTarget.closest("form")).find("input[type=text], input[type=number], select, input[type=radio]"), (_index, input) => {
    if ($(input.parentElement).find(".field_with_errors-inline")[0] !== undefined) {
      $($(input.parentElement).find(".field_with_errors-inline")[0]).remove();
    }
  });
  $.each($(event.currentTarget.closest("form")).find("input[type=radio]"), (_index, input) => {
    if ($(input.closest(".formbuilder-radio-group")).find(".field_with_errors-inline")[0] !== undefined) {
      $($(input.closest(".formbuilder-radio-group")).find(".field_with_errors-inline")[0]).remove();
    }
  });
};

export const addValidationsFieldsWithErrors = (event) => {
  addValidationsErrorsForInputsAndSelects(event);
  addValidationsErrorsForRadios(event);
  addValidationsErrorsForCheckboxes(event);
};

export const scrollToElement = (element) => {
  $([document.documentElement, document.body]).animate({
    scrollTop: $(element.parentElement).offset().top
  }, 1000);
};
