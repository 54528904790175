import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if ($("#modalNotification") !== undefined) {
      $("#modalNotification").modal({
        show: true
      });
    }
  }
}
