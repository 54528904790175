import Trix from "trix";

Trix.config.textAttributes.textColour = {
  styleProperty: "color",
  inheritable: true
};

addEventListener("trix-initialize", (event) => {
  event.target.toolbarElement.querySelector(
    ".trix-button-group--text-tools"
  ).insertAdjacentHTML(
    "beforeend",
    "<input type='color' class='trix-button trix-button--icon trix-button colourPicker'/>"
  );

  document.querySelectorAll(".colourPicker").forEach((colourPicker) => {
    colourPicker.addEventListener("input", (inputEvent) => {
      const trixElement = inputEvent.target.closest("trix-toolbar").nextElementSibling;
      trixElement.editor.activateAttribute("textColour", inputEvent.target.value);
    });
  });
});
