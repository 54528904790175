import { Controller } from "stimulus";
import Swal from "sweetalert2";

import {
  clearValidationsFieldsWithErrors,
  addValidationsFieldsWithErrors,
  scrollToElement
} from "./concerns/inputErrorMessagesConcern.js";

export default class extends Controller {
  static get targets() {
    return ["formRender", "dataConsentForm", "submitDataConsent", "checkboxDataConsent"];
  }

  connect() {
    if (this.dataConsentFormTarget.dataset.hasForm === "true") {
      const $formContainer = $(this.formRenderTarget);
      const fbOptions = {
        formData: this.formRenderTarget.dataset.formData,
        notify: {
          error(message) {
            // console.log("error");
            return console.error(message);
          },
          success(message) {
            // console.log("success");
            return console.log(message);
          },
          warning(message) {
            // console.log("warning");
            return console.warn(message);
          }
        }
      };
      $formContainer.formRender(fbOptions);
    }

    $(this.submitDataConsentTarget).on("click", (event) => {
      event.preventDefault();
      clearValidationsFieldsWithErrors(event);

      if (event.currentTarget.closest("form").checkValidity() === true) {
        if (this.checkboxDataConsentTarget.checked === true) {
          sessionStorage.setItem("formSubmissionProfile", true);
          $(this.dataConsentFormTarget).submit();
        } else {
          Swal.fire({
            title: "",
            text: "As respostas a este Perfil requerem a sua permissão para recolha de dados",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
          }).then((result) => {
            if (result.value === true) {
              this.checkboxDataConsentTarget.checked = true;
              sessionStorage.setItem("formSubmissionProfile", true);
            }
          });
        }
      } else {
        addValidationsFieldsWithErrors(event);

        const errorsMessages = $(".field_with_errors-inline");
        if (errorsMessages.length > 0) { scrollToElement(errorsMessages[0]); }
      }
    });
  }
}
