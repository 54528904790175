import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    $("#downloadInquiriesExcelBtn").on("click", (event) => {
      event.preventDefault();

      $(".download-excel-overlay").show();

      $.ajax({
        url: event.currentTarget.href,
        type: "get",
        success: (response) => {
          const downloadLink = window.document.createElement("a");
          downloadLink.href = response.file_path;
          downloadLink.download = "inquiries.xlsx";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
        error: (response) => {
          $(`<div class='container-fluid alert-container pl-0 pr-0'><p id='alert'>${response.responseJSON.error}</p></div>`)
            .insertAfter("#administration-menus");
        },
        complete: () => {
          $(".download-excel-overlay").hide();
        }
      });
    });
  }
}
