import { Controller } from "stimulus";
import {
  setAcceptedCookies,
  readAcceptedCookies,
} from "./concerns/modalCookiesConcern.js";

export default class extends Controller {
  connect() {
    if (window.ReactNativeWebView === undefined) {
      if (readAcceptedCookies() !== true) {
        $("#modalCookiesByHand").fadeIn();
      }

      this.closeCookiesModal = (_event) => {
        setAcceptedCookies();
        $("#modalCookiesByHand").fadeOut(500);
      };
    }
  }
}
