import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  connect() {
    const profilesListHeight = $(".profilesList").position().top + $(".profilesList").height();
    const mobileMenuPosition = $(".mobile-menu").position().top;
    if (profilesListHeight > mobileMenuPosition) { $(".mobile-menu-read-more").show(); }

    this.info_menu_toggle = (_event) => {
      $(".mobile-menu-btn").toggleClass("active not-active");
    };

    this.onScroll = (_event) => {
      if ($(".mobile-menu").is(":visible")) {
        // console.log(`${window.innerHeight} + ${window.scrollY} >= ${document.body.clientHeight}`)
        if (window.innerHeight + window.scrollY >= document.body.clientHeight) {
          $(".mobile-menu-read-more").fadeOut();
        } else {
          $(".mobile-menu-read-more").fadeIn();
        }
      }
    };

    this.homePageClick = (event) => {
      const nextUrl = event.currentTarget.href;
      if (window.formSubmissionProfile) {
        event.preventDefault();
        Swal.fire({
          title: "Quer mesmo sair?",
          text: "Irá perder todas as suas respostas.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((result) => {
          if (result.value === true) {
            window.formSubmissionProfile = undefined;
            window.location.href = nextUrl;
          }
        });
      }
    };
  }
}
