import { Controller } from "stimulus";

import $ from "jquery";
import "slick-carousel/slick/slick.js";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

export default class extends Controller {
  connect() {
    this.startProfile = (event) => {
      if (event.currentTarget.dataset.isform === "true") {
        window.formSubmissionProfile = true;
      }
    };

    $("#modulesSlickSlider").each((_index, element) => {
      if (element.classList.contains("slick-initialized") === false) {
        let size = parseInt(element.dataset.size, 10);
        if (size > 3) { size = 3; }

        $(element).slick({
          slidesToShow: size,
          slidesToScroll: 1,
          autoplay: true,
          prevArrow: $(".next_btn"),
          nextArrow: $(".previous_btn"),
          responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: size
              }
            }
          ]
        });
      }
    });
  }

  disconnect() {
    $("#modulesSlickSlider").each((_index, element) => {
      if (element.classList.contains("slick-initialized")) {
        $(element).slick("unslick");
      }
    });
  }
}
