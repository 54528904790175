import { Controller } from "stimulus";

export default class extends Controller {
  static get targets() {
    return ["charactersRemaining"];
  }

  connect() {
    this.updateBanner = (event) => {
      const element = event.currentTarget;
      if (element.files && element.files[0]) {
        const reader = new FileReader();
        reader.onload = (readerEvent) => { $("#banner_image").attr("src", readerEvent.target.result); };
        reader.readAsDataURL(element.files[0]);
      }
    };

    this.addField = (event) => {
      const time = new Date().getTime();
      const regexp = new RegExp($(event.currentTarget).data("id"), "g");
      $(event.currentTarget).before(
        $(event.currentTarget).data("fields").replace(regexp, time)
      );
      event.preventDefault();
    };

    this.removeField = (event) => {
      $(event.currentTarget).prev("input[type=hidden").val("1");
      $(event.currentTarget).closest("li").hide();
      event.preventDefault();
    };

    this.updateCharactersRemaining = (event) => {
      let len = 0;
      const message = event.currentTarget.value;
      for (let i = 0; i < message.length; i += 1) {
        len += 1;
      }
      this.charactersRemainingTarget.innerHTML = len;
    };
  }
}
